import '~js/bootstrap.ts'
import { onClickInputNumberSelect } from '~src/dom.ts'
import { setupToast } from '~src/globals/toastr.ts'
import { notifyUser } from '~public/js/src/default/jquery.js'

function mount() {
  onClickInputNumberSelect(['input[data-number]'])

  setupToast()
}
mount()

// See https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  // prevent error to be thrown and shown in sentry
  event.preventDefault()

  notifyUser()
})
