import '~public/css/src/tw/main.css'
import { appData } from '~src/globals/variables.ts'
import { initialize as initializeSentry } from '~src/globals/sentry.ts'

initializeSentry()

// Do not load evidentlyHelper code in production
if (appData.app.environment !== 'production') {
  void import('~src/globals/evidentlyHelper.ts').then((module) => module.mountEvidentlyHelper())
}
